<template lang="pug">
#beneficiosGaleria
    h1 {{contents.content.title}}
    .galleryContainer
        button#left-button.button(:disabled="galleryPosition <= 0" @click="moveGallery(-1)")
            FontAwesomeIcon(:icon="icons.faChevronLeft")
        ul#galeria
            li(v-for="beneficio in gallery" :class="{'hover': beneficio.isRedirect}")
                .image
                    img(:src="beneficio.image")
                .textContainer
                    p(v-html="beneficio.text").text
                .flip(v-if="beneficio.isRedirect")
                    a(:href="beneficio.link" target="_blank")
                        span.link Saiba Mais
        button#right-button.button(:disabled="verifyWidth()" @click="moveGallery(1)")
            FontAwesomeIcon(:icon="icons.faChevronRight")
</template>

<script>
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { props } from '@/mixins/component'
require("dotenv").config();

export default {
    name: "component-beneficios-galeria",
    props,
    data() {
        return {
            galleryPosition: 0,
            icons: {
                faChevronLeft, faChevronRight
            },
            gallery: [],
        }
    },
    methods: {
        moveGallery(amount) {
            const gallery = document.getElementById('galeria')
            this.galleryPosition += amount
            if (screen.width >= 721)
                gallery.style.transform = `translateX(-${this.galleryPosition * 105.9}%)`
            else
                gallery.style.transform = `translateX(-${this.galleryPosition * 112.0}%)`
        },
        verifyWidth() {
            if (screen.width >= 721)
                return this.galleryPosition >= (this.contents.content.gallery.length / 3) - 1
            else
                return this.galleryPosition >= this.contents.content.gallery.length - 1
        }
    },
    mounted() {
        this.gallery = this.contents.content.gallery.map(item => ({
            ...item,
            image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
        }))
    }
}
</script>

<style lang="stylus" scoped src="./BeneficiosGaleria.styl"></style>